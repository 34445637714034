@font-face {
    font-family: 'Tajawal';
    src: url('../fonts/Tajawal-Regular.ttf');
}

body {
    font-family: 'Tajawal' !important;
    color: #1e293b !important;
}

a {
    text-decoration: none;
    color: #1e293b;
}

a:hover {
    color: #1C4465;
}

header {
    backdrop-filter: none !important;
    background-color: transparent !important;
    position: absolute !important;
    padding: .5rem 0;
    border-bottom: 1px solid #c6c4c47a;

}

header .MuiToolbar-root {
    min-height: 64px !important;
}

.MuiTypography-root,
.MuiFormLabel-root,
.MuiButtonBase-root,
.MuiTableCell-root,
input,
textarea {
    font-family: 'Tajawal' !important;
    color: #1C4465;
}

.head {
    background: #1C4465;
    padding: 1rem;
    color: #fff;
}

.p-sidebar-right .p-sidebar {
    width: 25rem !important;
}

.p-dialog img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    padding: 10px;
    border: 1px solid #ced4da;
}

.MuiTableCell-root img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: auto;
}

.head2 {
    text-align: center;
    padding-top: 10px;

}

.card-content .content {
    line-height: 40px;
    padding: 2rem 3rem;
}

.app-total .MuiPaper-root {
    background-color: #D1E9FC !important;
}

.app-total .MuiPaper-root div {
    color: #000 !important;
    background: #eff3f8 !important;
}

.app-total .MuiPaper-root .MuiTypography-root {
    color: #000 !important;
    opacity: 1;
}

.MuiListItemButton-gutters {
    margin-bottom: 1rem;
}

.css-1aoarhy-MuiListItemText-root {
    font-weight: 500 !important;
}

.box-cat .MuiButtonBase-root {
    border-radius: 50% !important;
    width: 45px;
    height: 45px;
    min-width: 45px !important;
}

.box-cat img {
    height: 250px;
    object-fit: contain;
    width: 100%;
}

.box-cat {
    background: #fff;
}

.MuiDialog-paper {
    width: 500px;
}

.bg-login {
    background: #eff3f8;
    height: 100vh;
    padding: 5rem;
}

.bg-login .btn-bg {
    background: #1C4465;
    color: #fff;
}

.bg-login .pass svg {
    color: #6a6666;
}

.ic svg {
    color: #fff;
}

.logo {
    height: 120px;
    object-fit: contain;
}

input {
    width: 100% !important;
}

nav .MuiListItemText-root,
.MuiDrawer-root .MuiPaper-root .MuiListItemText-root {
    flex: none !important;
    -webkit-flex: none !important
}

.css-1byr0tz {
    background: #eff3f8;
    padding-top: 7rem;
}

.MuiTableHead-root .MuiTableCell-root {
    background: #1C4465;
    color: #fff;
}

nav .MuiPaper-root,
.MuiDrawer-root .MuiPaper-root {
    background: #fff;
    text-align: center;
    padding: 1rem 0;
    border: none;

}



.span-item,
.span-item a {
    color: #000;
}

.span-item a {
    text-decoration: underline;
}

.MuiDialogActions-root .MuiButtonBase-root:last-child {
    margin-right: 8px;
    margin-left: 0;
}

.img-contact {
    background: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    text-align: center;
}

.img-contact img {
    width: 30px;
    height: 30px;
}

nav a,
.MuiDrawer-root a {
    color: #1e293b !important;
    margin-bottom: 1rem;
}

nav ul {
    margin-top: 2rem !important;
}

nav .MuiListItemButton-root.active,
.MuiDrawer-root .MuiListItemButton-root.active {
    background-color: #eff3f8;
    box-shadow: 0px 2px 6px 1px #dcdee1;
    border-radius: 8px !important;
}

.MuiListItemText-root {
    font-size: 16px;
}




.custom-btn {
    background: #fff !important;
    font-family: 'Tajawal' !important;
    color: #1e293b !important;
    border: none !important;
    padding: 10px !important;
}

.custom-btn2 {
    background: #0b99e1 !important;
    color: #fff !important;
}



.btn-add {
    background: #fff !important;
    border: 1px solid #ced4da !important;
    color: #1e293b !important;
}


.box-list img {
    height: 300px;
    object-fit: cover;
}


.MuiButtonBase-root {
    border-radius: 0 !important;
}

.image-item {
    padding: 10px;
}

.upload__image-wrapper img {
    width: 200px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.p-button-icon-only {
    border-radius: 50px !important;
    margin-bottom: 10px !important;
}

.box {
    box-shadow: 0 0 3px 3px #00000036;
    background: #fff;
    padding: 1rem 3rem 4rem;
    color: #fff !important;

}

.box .MuiTypography-root {
    color: #000;
}

.button-group {
    display: inline-grid;
    display: -moz-inline-grid;
    display: -webkit-inline-grid;
}

.list-info img {
    object-fit: contain;
}

.MuiToolbar-root .MuiButtonBase-root .MuiSvgIcon-root {
    width: 35px !important;
    height: 35px !important;
}

.card img {
    width: 200px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.card .box-card {
    border-bottom: 1px solid #a19d9d95;
    padding: 2rem;

}

.card .box-card:last-child {
    border: none;
    margin: 0;
}

.banner {
    background-color: #0c99e0;
    padding: 7rem 0;
    color: #fff;

}

.page h5 {
    font-size: 22px;
    line-height: 40px;
}

.head-content {
    padding: 20px 0;
background: #0c99e0;
border-bottom: 2px solid #ffffff77;

}

.head-content .logo img{
    width: 200px;
    height: 120px;
    object-fit: contain;
}

.head-content a {
    color: #fff;
    padding-left: 15px;
    text-decoration: none;
font-size: 20px;

}

.head-content a:hover {
    color: #fff;
}